<template>
  <div>
    <br />
    <div class="d-flex justify-content-between align-items-center p-0">
      <div class="col-auto">
        <span class="p-2">
          Commande Référence Nº :
          <span class="fw-bold">
            {{ $route.params.reference }}
          </span>
        </span>
      </div>
      <div class="col-auto row">
        <div v-if="quotation.reference" class="col-auto p-1">
          <button @click="recalculationPrices(quotation.reference)" class="btn p-0">
            <i class="bi bi-coin text-secondary fs-4"></i>
          </button>
        </div>

        <div v-if="quotation.reference" class="col-auto p-1">
          <button @click="refresh()" class="btn p-0">
            <i class="bi bi-check2-square text-secondary fs-4"></i>
          </button>
        </div>

        <div v-if="quotation.reference" class="col-auto p-1">
          <button @click="refresh()" class="btn p-0">
            <i class="bi bi-pencil-square text-secondary fs-4"></i>
          </button>
        </div>
        <div v-if="quotation.reference" class="col-auto p-1">
          <button @click="print(quotation.reference)" class="btn p-0">
            <i class="bi bi-printer text-secondary fs-4"></i>
          </button>
        </div>
        <div class="col-auto p-1">
          <button @click="refresh()" class="btn p-0">
            <i class="bi bi-arrow-clockwise text-secondary fs-4"></i>
          </button>
        </div>
      </div>
    </div>
    <hr />
    <div class="row align-items-stretch">
      <div class="col-4">
        <div class="row justify-content-between">
          <div class="col p-1">Client</div>
          <div class="col p-1 fw-bold">
            <span v-if="quotation.customer">
              {{ quotation.customer.fullName }}
            </span>
          </div>
        </div>

        <div class="row justify-content-between">
          <div class="col p-1">Nº de Téléphone</div>
          <div class="col p-1 fw-bold">
            <span v-if="quotation.customer">
              {{ quotation.customer.phone }}
            </span>
          </div>
        </div>

        <div class="row justify-content-between">
          <div class="col p-1">Nom Commercial (Société)</div>
          <div class="col p-1 fw-bold">
            <span v-if="quotation.customer">
              {{ quotation.customer.society }}
            </span>
          </div>
        </div>
      </div>
      <!-- ***** -->
      <div class="col-4 justify-content-between">
        <div class="row">
          <div class="col p-1">Quantité totale</div>
          <div v-if="quotation.totalQuantity" class="col p-1 fw-bold">
            {{ quotation.totalQuantity }}
          </div>
        </div>

        <div class="row">
          <div class="col p-1">Montant Total TTC</div>
          <div v-if="quotation.totalPriceTTC" class="col p-1 fw-bold">
            {{ quotation.totalPriceTTC }}
          </div>
        </div>

        <div class="row">
          <div class="col p-1">Montant Total HT</div>
          <div v-if="quotation.totalPriceHT" class="col p-1 fw-bold">
            {{ quotation.totalPriceHT }}
          </div>
        </div>

        <div class="row">
          <div class="col p-1">Montant Total TVA</div>
          <div v-if="quotation.totalPriceTVA" class="col p-1 fw-bold">
            {{ quotation.totalPriceTVA }}
          </div>
        </div>
      </div>
      <!--  -->
      <div class="col-4 justify-content-between">
        <div class="row">
          <div class="col p-1">Total Payé :</div>
          <div class="col p-1 fw-bold">
            {{ quotation.amountPaid }}
          </div>
        </div>

        <div class="row">
          <div class="col p-1">Montant Impayé :</div>
          <div class="col p-1 fw-bold">
            {{ quotation.amountUnpaid }}
          </div>
        </div>
      </div>
    </div>

    <br />
    <div class="row align-items-stretch">
      <div class="col-4">
        <div class="row justify-content-between">
          <div class="col p-1">État de livraison</div>
          <div class="col p-1 fw-bold">
            <span v-if="quotation.customer"> - </span>
          </div>
        </div>

        <div class="row justify-content-between">
          <div class="col p-1">Livré par :</div>
          <div class="col p-1 fw-bold">
            <span v-if="quotation.customer"> - </span>
          </div>
        </div>

        <div class="row justify-content-between">
          <div class="col p-1">Livré le :</div>
          <div class="col p-1 fw-bold">
            <span v-if="quotation.customer"> - </span>
          </div>
        </div>
      </div>

      <div class="col-4">
        <div class="row justify-content-between">
          <div class="col p-1">Vérifié (oui/non) :</div>
          <div class="col p-1 fw-bold">
            <span v-if="quotation.customer"> - </span>
          </div>
        </div>

        <div class="row justify-content-between">
          <div class="col p-1">Vérifié par :</div>
          <div class="col p-1 fw-bold">
            <span v-if="quotation.customer"> - </span>
          </div>
        </div>

        <div class="row justify-content-between">
          <div class="col p-1">Vérifié à :</div>
          <div class="col p-1 fw-bold">
            <span v-if="quotation.customer"> - </span>
          </div>
        </div>
      </div>

      <div class="col-4">
        <div class="row justify-content-between">
          <div class="col p-1">Validé (oui/non) :</div>
          <div class="col p-1 fw-bold">
            <span v-if="quotation.customer"> - </span>
          </div>
        </div>

        <div class="row justify-content-between">
          <div class="col p-1">Validé par :</div>
          <div class="col p-1 fw-bold">
            <span v-if="quotation.customer"> - </span>
          </div>
        </div>

        <div class="row justify-content-between">
          <div class="col p-1">Validé à :</div>
          <div class="col p-1 fw-bold">
            <span v-if="quotation.customer"> - </span>
          </div>
        </div>
      </div>
    </div>
    <br />
    <br />

    <table class="table table-striped table-hover">
      <thead>
        <tr>
          <th scope="col"></th>
          <th scope="col">Référence</th>
          <th scope="col">Nom</th>
          <th scope="col">Quantité</th>
          <th scope="col">Prix U (TTC)</th>
          <th scope="col">M. Total</th>
          <th scope="col">Créer par</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in quotation.quotation_items" :key="index++">
          <td>
            {{ index }}
          </td>
          <td>
            {{ item.item_reference }}
          </td>
          <td>
            <span v-if="item.item">
              {{ item.item.fullName }}
            </span>
          </td>
          <td>{{ item.quantity }}</td>
          <td class="text-warning">
            {{ item.priceTTC }}
          </td>
          <td class="text-warning">
            {{ item.totalPriceTTC }}
          </td>
          <td class="text-success">
            {{ item.payrollAmount }}
          </td>

          <td>
            <span v-if="item.user">
              {{ item.user.name }}
            </span>
          </td>
        </tr>
      </tbody>
    </table>
    <br />
    <br />
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {};
  },

  mounted() {
    this.$store.dispatch("quotation/show", this.$route.params.reference);
  },
  computed: {
    ...mapGetters("quotation", {
      quotation: "getQuotation",
    }),
  },
  methods: {
    async refresh() {
      await this.$store.dispatch("quotation/show", this.$route.params.reference);
    },
    async recalculationPrices(reference) {
      await this.$store.dispatch("quotation/recalculationPrices", reference);
    },

    async print(reference) {
      await this.$store.dispatch("quotation/print", reference);
    },
  },
};
</script>
